const { ethers } = require('ethers')
const BazaNFTAbi = require('@bazarion/protocol/abis/baza/BazaNFT.json')
const { MULTICALLS } = require('./constants')
const { Contract, Provider } = require('ethcall')

class NFT {
    constructor(nft, provider) {
        let signer = provider.getSigner()
        if (provider.connection.url !== 'metamask') {
            let randomWallet = ethers.Wallet.createRandom()
            signer = new ethers.Wallet(randomWallet.privateKey, provider)
        }
        this.nft = new ethers.Contract(nft, BazaNFTAbi, signer)
        this.address = this.nft.address
        this.provider = provider
    }

    symbol() {
        return this.nft.symbol()
    }

    name() {
        return this.nft.name()
    }

    balanceOf(addr) {
        return this.nft.balanceOf(addr)
    }

    tokenOfOwnerByIndex(addr, idx) {
        return this.nft.tokenOfOwnerByIndex(addr, idx)
    }

    tokenByIndex(idx) {
        return this.nft.tokenByIndex(idx)
    } 

    totalSupply() {
        return this.nft.totalSupply()
    }

    async tokenURI(tokenId) {
        return this.nft.tokenURI(tokenId)
    }

    async setApprovalForAll(addr, b) {
        return this.nft.setApprovalForAll(addr, b)
    }

    async isApprovedForAll(owner, addr) {
        return this.nft.isApprovedForAll(owner, addr)
    }

    async nftOfOwnerByIndex(owner, from, to) {
        const ethcallProvider = new Provider()
        return ethcallProvider.init(this.provider).then(async () => {
            return this.provider.getNetwork().then(async net => {
                const multi = MULTICALLS.find(n => (n.chainId === '0x' + net.chainId.toString(16)))
                ethcallProvider.multicall = { address: multi.address, block: 0 }

                let nftContract = new Contract(this.address, BazaNFTAbi)

                let calls = []
                for (let i = from; i < to; i++) {
                    calls.push(nftContract.tokenOfOwnerByIndex(owner, i))
                }
                let data = await ethcallProvider.all(calls)
                return data
            })
        })
    }

}

module.exports = NFT
