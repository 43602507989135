const { ethers } = require('ethers')
const MintBoxAbi = require('@bazarion/protocol/abis/baza/MintBox.json')
const mintBoxContracts = require('@bazarion/protocol/mintBox.json')
const { NETWORKS, MULTICALLS } = require('./constants')
const { Contract, Provider } = require('ethcall')
const BazaAbi = require('@bazarion/protocol/abis/baza/Baza.json')

class MintBox {
    constructor(addrMintBox, network, provider, signer) {
        this.mintBox = new ethers.Contract(addrMintBox, MintBoxAbi, signer)
        this.address = this.mintBox.address
        this.network = network
        this.provider = provider
    }

    static async setMintBox(provider) {
        let signer = provider.getSigner()
        let ok =  true
        try {
            await signer.getAddress()
        } catch (e) {
            ok = false
        }
        if (provider.connection.url !== 'metamask' || !ok) {
            let randomWallet = ethers.Wallet.createRandom()
            signer = new ethers.Wallet(randomWallet.privateKey, provider)
        }
        return provider.getNetwork().then(net => {
            const network = NETWORKS.find(n => (n.chainId === '0x' + net.chainId.toString(16))) || []
            const contract = mintBoxContracts.find(c => (c.ChainId === net.chainId))

            return new MintBox(contract.MintBox, network, provider, signer)
        })
    }

    async getCurrencyInfo(sale) {
        if (sale.currency === ethers.constants.AddressZero) {
            return {
                ftSymbol: this.network.nativeCurrency.symbol,
                ftDecimals: this.network.nativeCurrency.decimals
            }
        }

        const ethcallProvider = new Provider()
        return ethcallProvider.init(this.provider).then(async () => {
            return this.provider.getNetwork().then(async net => {
                const multi = MULTICALLS.find(n => (n.chainId === '0x' + net.chainId.toString(16)))
                ethcallProvider.multicall = { address: multi.address, block: 0 }

                let ftContract = new Contract(sale.currency, BazaAbi)

                let data = await ethcallProvider.all([
                    ftContract.symbol(),
                    ftContract.decimals()
                ])
                let ftSymbol = data[0]
                let ftDecimals = data[1]
                return { ftSymbol, ftDecimals }
            })
        })
    }

    buy(item, round, amount) {
        return this.mintBox.buy(item, round, amount)
    }
    
    buyETH(item, round, amount, price) {
        return this.mintBox.buyETH(item, round, amount, {
            value: ethers.BigNumber.from(price).mul(amount)
        })
    }

    openBox(item, tokenIds) {
        return this.mintBox.openBox(item, tokenIds)
    }

    getSales(item) {
        return this.mintBox.getSales(item)
    }

    getRounds(item, round) {
        return this.mintBox.getRounds(item, round)
    }

    getRoundsLength(item) {
        return this.mintBox.getRoundsLength(item)
    }

    getWhitelistedQty(item, round, to) {
        return this.mintBox.getWhitelistedQty(item, round, to)
    }
}

module.exports = MintBox
