const constants = require('./constants')
const Market = require('./market')
const Multicall = require('./multicall')
const Factory = require('./factory')
const FT = require('./ft')
const NFT = require('./nft')
const MintBox = require('./mintBox')
const MintItemCatWL = require('./mintItemCatWL')
const contracts = require('./contracts')
const { ethers } = require('ethers')

const NETWORKS = constants.NETWORKS

function getProvider (chainId) {
    const network = NETWORKS.find(n => (n.chainId === '0x' + chainId.toString(16)))
    return new ethers.providers.StaticJsonRpcProvider(network.rpcUrls[0], { chainId: chainId })
}

function getWETH (chainId) {
    const network = NETWORKS.find(n => (n.chainId === '0x' + chainId.toString(16)))
    return network.nativeCurrency.wrappedToken
}

module.exports = {
    Market,
    Factory,
    FT,
    NFT,
    constants,
    contracts,
    getProvider,
    getWETH,
    Multicall,
    MintBox,
    MintItemCatWL
}
