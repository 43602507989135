<template>
    <b-container class="boxpage">
        <div class="box-wrap mb-4">
            <b-tabs content-class="mt-4">
                <b-tab title="Mint" :active="tabActive === 1">
                    <b-card class="mint-box" no-body>
                        <b-card-img v-if="!loading" class="nft-image mb-3" v-bind:src="require('../assets/images/box.png')" alt="image"></b-card-img>
                        <b-skeleton-img v-else></b-skeleton-img>
                        <b-card-body v-if="!loading">
                            <b-card-text v-if="currentId < boxSupply">Sale progress: <span class="text-success">{{ `${currentId}/${boxSupply}` }}</span></b-card-text>
                            <b-card-text v-else>Sale progress: <span class="text-danger">{{ `${currentId} - SOLD OUT` }}</span></b-card-text>
                            <b-progress :max="boxSupply" height="2px">
                                <b-progress-bar :value="currentId">
                                </b-progress-bar>
                                <b-progress-bar variant="danger" :value="boxSupply - currentId"></b-progress-bar>
                            </b-progress>
                        </b-card-body>
                        <b-card-body v-else>
                            <b-card-text>
                            <b-skeleton animation="fade" width="100%"></b-skeleton>
                            </b-card-text>
                            <b-card-text>
                            <b-skeleton animation="fade" width="100%"></b-skeleton>
                            </b-card-text>
                        </b-card-body>
                        <b-card-body v-for="(round, k) in rounds" :key="String(k)">
                            <b-card-title>{{ round.isPublic ? 'Public Sale' : `Pre-Sale`}}</b-card-title>
                            <b-card-text class="sale-info" :class="(whitelistedQty[k] > 0) ? 'text-success' : 'text-danger'" v-if="!round.isPublic"><b-icon-check-square /> {{ `Whitelisted ${whitelistedQty[k]} spots` }}</b-card-text>
                            <b-card-text class="sale-info" :class="(new Date() >= (round.startTime * 1000)) ? 'text-success' : 'text-danger'"><b-icon-skip-start-circle /> {{ new Date(round.startTime * 1000).toLocaleString()  }}</b-card-text>
                            <b-card-text class="sale-info" :class="(new Date() <= (round.endTime * 1000)) ? 'text-success' : 'text-danger'"><b-icon-stop-circle /> {{ new Date(round.endTime * 1000).toLocaleString() }}</b-card-text>
                            <b-card-text v-if="delta[k] > 0" class="sale-info"><b-icon-clock /> <CountDown :text="deltaText[k]" :time="`${1000 * delta[k]}`" /></b-card-text>
                            <b-card-text class="mt-3">
                            <b-form>
                                <b-form-group
                                     label-for="box-amount"
                                     >
                                     <b-input-group 
                                     id="box-amount"
                                     required
                                     :prepend="`${utils.bigToLabel(round.price, ftDecimals, 2)} x`"
                                     :append="`= ${utils.bigToLabel(utils.priceToTotal(amount[k], round.price), ftDecimals, 2)} ${ftSymbol}`">
                                         <b-form-input :readonly="isNotMinted[k]" min="0" :max="whitelistedQty[k]" placeholder="Enter box amount" v-model="amount[k]" type="number"></b-form-input>
                                     </b-input-group>
                                     <b-form-input v-model="amount[k]" type="range" min="0" :max="whitelistedQty[k]" class="mt-1"></b-form-input>
                                </b-form-group>
                            </b-form>
                            <b-button
                              class="btn-custom btn-mint"
                              :disabled='isDisabled || isNotMinted[k]'
                              @click="mint(k, amount[k], round.price)"
                              >
                              Mint
                            </b-button>
                            </b-card-text>
                        </b-card-body>
                    </b-card>
                </b-tab>
                <b-tab :title="`Open(${boxBalance})`" :active="tabActive === 2">
                    <b-card>
                        <b-card-body v-if="!loading">
                            <b-card-text>You have {{ boxBalance }} boxes</b-card-text>
                            <b-card-text class="sale-info" :class="(new Date() > saleInfo.openTime * 1000) ? 'text-success' : 'text-danger'"><b-icon-clock /> {{ new Date(saleInfo.openTime * 1000).toLocaleString() }}</b-card-text>
                            <b-form>
                                <b-form-group
                                     label-for="box-amount"
                                     >
                                     <b-form-input
                                     placeholder="Enter box amount"
                                     :disabled='isDisabled'
                                     v-model="openAmount"
                                     type="number"
                                     :readonly="isNotOpen"
                                     required
                                     ></b-form-input>
                                </b-form-group>
                            </b-form>
                            <b-button
                              class="btn-custom btn-open"
                              :disabled='isDisabled || isNotOpen'
                              @click="open(openAmount)"
                              >
                              Open
                            </b-button>
                        </b-card-body>
                        <b-card-body v-else>
                            <b-card-text>
                            <b-skeleton animation="fade" width="100%"></b-skeleton>
                            </b-card-text>
                            <b-card-text>
                            <b-skeleton animation="fade" width="100%"></b-skeleton>
                            </b-card-text>
                            <b-card-text>
                            <b-skeleton animation="fade" width="100%"></b-skeleton>
                            </b-card-text>
                        </b-card-body>
                    </b-card>
                </b-tab>
                <b-tab :title="`Items(${itemBalance})`" :active="tabActive === 3">
                    <div class="no-item" v-if="!loading && items.length === 0">
                        <b-card-img class="nologo" :src="require('../assets/images/nologo.svg')" alt="Image"></b-card-img>
                        <b-card-text>Your items will be shown here.</b-card-text>
                    </div>
                    <b-row class="is-multiline">
                        <b-col v-if="!loading && items.length > 0" class="is-flex">
                            <b-card v-for="(it, k) in items" :key="k" no-body class="nft-item">
                                <router-link class="item-link" :to="`/${chainId}/market/${ftLink}/${itemAddress}/${it.tokenId}`">
                                    <b-card-img class="nft-image" :class="{ 'pixelated': isPixelated}" v-bind:src="it.image || `data:image/svg+xml;charset=UTF-8,%3Csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' preserveAspectRatio='none'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E%23holder_17cb7089c7d text %7B fill:'%23DFE1E6';font-weight:bold;font-family:Poppins, monospace;font-size:50pt %7D %3C/style%3E%3C/defs%3E%3Cg id='holder_17cb7089c7d'%3E%3Crect width='200' height='200' fill='%23EBECF0'%3E%3C/rect%3E%3Cg%3E%3Ctext x='44.421875' y='118.5' fill='%23DFE1E6'%3E%23${it.tokenId}%3C/text%3E%3C/g%3E%3C/g%3E%3C/svg%3E`" alt="image"></b-card-img>
                                    <b-card-body class="is-flex">
                                        <div class="nft-head">
                                            <b-card-title class="nft-title highlight-text-color">{{ utils.truncate(`#${it.tokenId}`, 8) }}</b-card-title>                                            
                                        </div>
                                    </b-card-body>
                                </router-link>
                            </b-card>
                            <b-button class="btn-load-more mt-4 mb-4" v-if="items.length < itemBalance" @click="loadItems(items.length, items.length + limit)">Next <b-spinner v-if="loadingMore" small label="Small Spinner"></b-spinner></b-button>
                        </b-col>
                        <!-- Skeleton loading -->
                        <b-col v-if="loading" class="is-flex" style="margin-top: 20px">
                            <b-card v-for="(i) in [1,2,3,4]" :key="i" no-body class="nft-item">
                                <b-skeleton-img></b-skeleton-img>
                                <b-card-body class="is-flex space-between">
                                    <div class="nft-head">
                                        <b-skeleton animation="fade" width="100%"></b-skeleton>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <!-- End skeleton loading -->
                    </b-row>
                </b-tab>
            </b-tabs>
		</div>
        <b-modal id="mint-result" no-close-on-backdrop centered title="Transaction Confirmed">
            Congratulation! <br >
            You successfully minted <strong>{{ mintedAmount }}</strong> items for <strong>{{ utils.bigToLabel(mintedPrice, ftDecimals, 2) }} {{ ftSymbol }}</strong> on Bazarion <br />
            You can check out your recent activity <b-link target="_blank" :href="utils.txLink(chainId, txHash)">here</b-link>.
            <template #modal-footer="{ ok }">
                <b-button variant="success" @click="ok()">
                    OK
                </b-button>
                <b-button variant="outline-secondary" @click="tabActive=2;ok()">
                    Open Box
                </b-button>
            </template>
        </b-modal>
        <b-modal id="open-result" no-close-on-backdrop centered title="Transaction Confirmed">
            Congratulation!
            <template #modal-footer="{ ok }">
                <b-button variant="success" @click="ok()">
                    OK
                </b-button>
                <b-button variant="outline-secondary" @click="tabActive=3;ok()">
                    View Items
                </b-button>
            </template>
        </b-modal>
        <b-toast toaster="b-toaster-top-left" id="tx-success" :title="`${txDone}/${txRequired} Transaction confirmed`" variant="success" solid auto-hide-delay="8000">
            Your transaction is successful. <b-link class="view-explorer" target="_blank" :href="utils.txLink(chainId, txHash)"><b-icon icon="box-arrow-up-right"></b-icon> View Transaction</b-link>
        </b-toast>
        <b-toast toaster="b-toaster-top-left" id="tx-confirm" :title="`${txDone}/${txRequired} Transaction confirmed`" no-auto-hide variant="info">
            Your transaction is besing processed.
        </b-toast>
        <b-toast toaster="b-toaster-top-left" id="tx-wallet" :title="`${txDone}/${txRequired} Transaction confirmed`" no-auto-hide variant="info">
            Confirm wallet transaction to complete {{ txTask }}.
        </b-toast>
        <b-toast toaster="b-toaster-top-left" id="tx-error" :title="`${txDone}/${txRequired} Transaction confirmed`" variant="danger" solid auto-hide-delay="8000">
            Your transaction was not successfully broadcasted.
        </b-toast>
    </b-container>
</template>
<script>

import axios from 'axios'
import { ethers } from 'ethers'
import { MintBox, NFT, FT } from '@bazarion/sdk'
import CountDown from './CountDown.vue'

export default {
    name: 'Box',
    components: { CountDown },
    data: () => ({
        isDisabled: true,
        txDone: 0,
        txRequired: 1,
        txHash: '',
        txTask: 'Mint Box',
        isConnect: true,
        mintBox: {},
        saleInfo: {},
        rounds: [],
        itemAdderss: '',
        ftSymbol: '',
        ftDecimals: 18,
        amount: [1, 1],
        boxBalance: 0,
        openAmount: 0,
        nftBox: {},
        nftItem: {},
        itemBalance: 0,
        items: [],
        limit: 12,
        loading: true,
        loadingMore: false,
        isPixelated: false,
        maxOpen: 50,
        ftLink: '',
        boxIds: [],
        boxSupply: 0,
        currentId: 0,
        isBoxApproved: false,
        whitelistedQty: [0, 0],
        isNotMinted: [false, false],
        isNotOpen: true,
        isSoldOut: false,
        delta: [0, 0],
        deltaText: ['Start in', 'End in'],
        tabActive: 1,
        mintedAmount: 0,
        mintedPrice: 0
    }),
    props: [ ],
    created: async function () {
        try {
            this.provider = await this.getProvider(parseInt(this.$route.params.chainId))

            this.isConnect = (this.provider.connection.url !== 'metamask')
            this.address = await this.provider.getSigner().getAddress()
        } catch(e) {
            console.log(e)
            this.isConnect = true
        }

        this.itemAddress = this.$route.params.item
        this.mintBox = await MintBox.setMintBox(this.provider)
        let roundsLength = (await this.mintBox.getRoundsLength(this.itemAddress)).toNumber()
        for (let i = 0; i < roundsLength; i++) {
            this.rounds.push(await this.mintBox.getRounds(this.itemAddress, i))
        }
        await this.getMintBoxInfo()
    },
    updated: async function () {
        this.$nextTick().then(() => { 
            let nftImg = document.querySelector(".nft-image")
            if (nftImg) {
                if (nftImg.naturalWidth < 200) {
                    this.isPixelated = true
                } else {
                    this.isPixelated = false
                }
            }
        })
    },
    methods: {
        async getMintBoxInfo () {
            this.saleInfo = await this.mintBox.getSales(this.itemAddress)
            this.boxSupply = this.saleInfo.boxSupply.toNumber()
            this.currentId = this.saleInfo.currentId.toNumber()
            let info = await this.mintBox.getCurrencyInfo(this.saleInfo)
            this.ftSymbol = info.ftSymbol
            this.ftDecimals = info.ftDecimals
            this.ftLink = (this.saleInfo.currency === ethers.constants.AddressZero) ?
                this.ftSymbol : this.saleInfo.currency

            let box = this.saleInfo.box
            this.nftBox = new NFT(box, this.provider)
            this.nftItem = new NFT(this.itemAddress, this.provider)
            this.itemBalance = (await this.nftItem.balanceOf(this.address)).toNumber()
            this.boxBalance = (await this.nftBox.balanceOf(this.address)).toNumber()
            this.openAmount = this.boxBalance
            this.items = []
            this.loadItems(0, (this.itemBalance > this.limit) ? this.limit : this.itemBalance)
            this.loading = false

            let from = 0
            let to = (this.boxBalance > this.maxOpen) ? this.maxOpen : this.boxBalance
            this.boxIds = await this.nftBox.nftOfOwnerByIndex(this.address, from, to)
            this.isBoxApproved = (await this.nftBox.isApprovedForAll(this.address, this.mintBox.address))
            this.whitelistedQty[0] = (await this.mintBox.getWhitelistedQty(this.itemAddress, 0, this.address)).toNumber()
            this.whitelistedQty[1] = (this.rounds[1].maxItem).toNumber()
            this.amount[0] = this.whitelistedQty[0]
            this.amount[1] = this.whitelistedQty[1]
            let currentTime = parseInt(Date.now() / 1000)
            this.isSoldOut = !(this.currentId < this.boxSupply)
            this.isNotMinted[0] = (this.whitelistedQty[0] === 0) || currentTime < this.rounds[0].startTime || currentTime > this.rounds[0].endTime || this.isSoldOut
            this.isNotMinted[1] = (this.whitelistedQty[1] === 0) || currentTime < this.rounds[1].startTime || currentTime > this.rounds[1].endTime || this.isSoldOut
            if (currentTime < this.rounds[0].endTime) {
                this.delta[0] = this.rounds[0].endTime - currentTime
                this.deltaText[0] = 'End in'
            }
            if (currentTime < this.rounds[1].endTime) {
                this.delta[1] = this.rounds[1].endTime - currentTime
                this.deltaText[1] = 'End in'
            }
            if (currentTime < this.rounds[0].startTime) {
                this.delta[0] = this.rounds[0].startTime - currentTime
                this.deltaText[0] = 'Start in'
            }
            if (currentTime < this.rounds[1].startTime) {
                this.delta[1] = this.rounds[1].startTime - currentTime
                this.deltaText[1] = 'Start in'
            }
            this.isNotOpen = (currentTime < this.saleInfo.openTime)
            this.isDisabled = false
        },
        async mint (round, amount, price) {
            this.txDone = 0
            this.txRequired = 1
            this.isDisabled = true
            try {
                if (this.saleInfo.currency !== ethers.constants.AddressZero) {
                    let ft = new FT(this.saleInfo.currency, this.provider)
                    let ftApproved = !(await ft.allowance(this.address, this.nftBox.address)).eq(0)
                    if (!ftApproved) {
                        this.txDone = 0
                        this.txRequired = 2
                        this.txTask = `Approve ${this.ftSymbol}`
                        this.$bvToast.show('tx-wallet')
                        let tx0 = await ft.approve(this.nftBox.address)
                        this.$bvToast.show('tx-confirm')
                        this.$bvToast.hide('tx-wallet')
                        this.txHash = tx.hash
                        await tx0.wait()
                        this.txDone = this.txDone + 1
                        this.$bvToast.show('tx-success')
                        this.$bvToast.hide('tx-confirm')
                    }

                }
                this.txTask = 'Mint Box'
                this.$bvToast.show('tx-wallet')
                let tx
                if (this.saleInfo.currency !== ethers.constants.AddressZero) {
                    tx = await this.mintBox.buy(this.itemAddress, round, amount)
                } else {
                    tx = await this.mintBox.buyETH(this.itemAddress, round, amount, price)
                }
                this.$bvToast.show('tx-confirm')
                this.$bvToast.hide('tx-wallet')
                this.txHash = tx.hash
                await tx.wait()
                this.txDone = this.txDone + 1
                this.$bvToast.show('tx-success')
                this.$bvToast.hide('tx-confirm')
                await this.getMintBoxInfo()
                this.mintedAmount = amount
                this.mintedPrice = price.mul(amount)
                this.$bvModal.show('mint-result')
            } catch (e) {
                this.$bvToast.hide('tx-confirm')
                this.$bvToast.hide('tx-wallet')
                this.$bvToast.show('tx-error')
                this.isDisabled = false
            }
        },
        async open (amount) {
            this.txDone = 0
            this.txTask = 'Approve Box'
            this.isDisabled = true
            try {
                if (!this.isBoxApproved) {
                    this.txRequired = 2
                    this.$bvToast.show('tx-wallet')
                    let tx0 = await this.nftBox.setApprovalForAll(this.mintBox.address, true)
                    this.$bvToast.show('tx-confirm')
                    this.$bvToast.hide('tx-wallet')
                    this.txHash = tx0.hash
                    await tx0.wait()
                    this.txDone = 1
                    this.$bvToast.show('tx-success')
                    this.$bvToast.hide('tx-confirm')
                } else {
                    this.txRequired = 1
                }

                let tokenIds = []
                for (let i = 0; i < amount; i++) {
                    tokenIds.push(this.boxIds[i].toNumber())
                }

                this.txTask = 'Open Box'
                this.$bvToast.show('tx-wallet')
                let tx1 = await this.mintBox.openBox(this.itemAddress, tokenIds)
                this.$bvToast.show('tx-confirm')
                this.$bvToast.hide('tx-wallet')
                this.txHash = tx1.hash
                await tx1.wait()
                this.txDone = this.txDone + 1
                this.$bvToast.show('tx-success')
                this.$bvToast.hide('tx-confirm')
                await this.getMintBoxInfo()
                this.$bvModal.show('open-result')
            } catch (e) {
                this.$bvToast.hide('tx-confirm')
                this.$bvToast.hide('tx-wallet')
                this.$bvToast.show('tx-error')
                this.isDisabled = false
            }
        },
        loadItems: async function (from, to) {
            this.loadingMore = true
            to = (to > this.itemBalance) ? this.itemBalance : to
            let items = await this.nftItem.nftOfOwnerByIndex(this.address, from, to)
            items.forEach(async (tokenId) => {
                let uri = await this.nftItem.tokenURI(tokenId)
                try {
                    let { data } = await axios.get(this.utils.nftIpfsLink(uri))
                    data.tokenId = tokenId.toString()
                    this.items.push(data)
                } catch(e) {
                    this.items.push({
                        tokenId: tokenId.toString(),
                        image: ''
                    })
                    /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
                }
            })
            this.loadingMore = false
        }
    }
};

</script>

<style lang="scss">
    .container.boxpage{
        width: 100%;
        max-width: 500px;
        margin-top: 40px;
        .btn-mint, .btn-open{
            margin: 0 auto;
            padding: 5px 10px;
            border-radius: 8px;
            color: #fff;
            background-color: #9D22C1;
        }
        .sale-info{
            font-size: 80%;
            margin-bottom: 0.8rem;
        }
        .btn-load-more{
            margin: 0 auto;
            padding: 5px 10px;
            border-radius: 8px;
            color: #fff;
            background-color: #9D22C1;
        }
        .mint-box{
            border: 0;
        }
        .nft-item{
            width: calc(50% - 14px);
            margin-right: 24px;
            margin-bottom: 10px;
            padding: 16px;
            border-radius: 8px;
            background-color: #F4F5F7;
            border-color: transparent;
            transition: all 0.35s ease;
            cursor: pointer;
            &:nth-child(2n) {
                margin-right: 0;
            }
            .item-link{
                &:hover{
                    text-decoration: none;
                }
            }
            &:nth-child(4n) {
                margin-right: 0;
            }
            &:hover{
                background-color: #fff;
                box-shadow: 0px 0px 8px 2px #e9e9e9;
            }
            .card-body{
                padding: 16px 0 0 0;
            }
            .is-flex{
                justify-content: space-between;
            }
            .nft-head{
                width: 61%;
            }
            .nft-price{
                width: 39%;
            }
            .nft-acc{
                font-size: 12px;
                margin-bottom: 0;
                color: #FEBC11;
                line-height: 16px;
            }
            .nft-title{
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;
                margin-bottom: 0;
            }
            .nft-price-text{
                font-size: 12px;
                margin-bottom: 0;
                color: #7A869A;
                text-align: right;
            }
            .nft-price-value{
                font-size: 20px;
                text-align: right;
            }
        }
        .no-item{
            margin-top: 150px;
            text-align: center;
            color: #5E6C84;
            .nologo{
                max-width: 80px;
                margin-bottom: 16px;
            }
        }
    }
    #mint-result, #open-result{
        .modal-content{
            border-radius: 16px;
            overflow: hidden;
        }
        .modal-header{
            background
            : linear-gradient(243.18deg, #f4d4fd 0%, #ffffff 100%);
            justify-content: center;
            border-bottom: none;
            .modal-title{
                text-align: center;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
</style>
