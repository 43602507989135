<template>
    <div class="list-market">
        <b-container>
            <div class="is-flex header-account">
                <h1 class="is-flex address mb-0">
                    <span>Markets</span>
                </h1>
            </div>
            <div class="mt-3">
                <b-table small :items="allMarkets" :fields="fields" responsive>
                    <template #cell(action)="data">
                        <b-link :href="`/${chainId}/market/${data.value.ftLink}/${data.value.nftAddress}`">Trade</b-link> | <b-link :href="`/${chainId}/info/${data.value.ftLink}/${data.value.nftAddress}`">Info</b-link>  
                    </template>
                </b-table>
            </div>
        </b-container>
    </div>
</template>
<script>

import { Factory, Multicall, getWETH } from '@bazarion/sdk'
import { ethers } from 'ethers'
import axios from 'axios'

export default {
    name: 'ListMarket',
    components: {},
    data: () => ({
        fields: [ {
            key: 'ftSymbol',
            label: 'Currency'
        }, {
            key: 'nftSymbol',
            label: 'Item'
        }, 'totalSupply', {
            key: 'tvl',
            label: 'TVL',
            sortable: true 
        }, { key: 'floorPrice', sortable: true }, 'latestPrice', 'totalVolume', 'volume24h', 'action', ],
        allMarkets: []
    }),
    created: async function () {
        this.provider = await this.getProvider(parseInt(this.$route.params.chainId))
        let factory = await Factory.setProvider(this.provider)
        let markets = await factory.getAllMarkets()
        let tokens = await Multicall.getTokensInMarkets(markets, this.provider)
        let items = []
        let weth = getWETH(this.chainId)
        for (let i = 0; i < tokens.length / 2; i++) {
            try {
                let calls = await Multicall.setMulticall(tokens[i*2], tokens[i*2 + 1], this.provider)
                let {
                    //totalSupply, exchangeFee, withdrawFee, treasuryFee, stakeRate, tvl, ftDecimals,
                    ftSymbol, nftSymbol, nftName, ftDecimals, tvl, totalSupply
                    //, marketTotalSupply, bestBidMarketPrice
                } = await calls.getMarketInfo()
                let item = { ftSymbol, nftSymbol, nftName, ftDecimals }
                item.tvl = this.utils.bigToLabel(tvl, item.ftDecimals)
                let ftLink =  (weth === tokens[i*2]) ? ftSymbol : tokens[i*2]
                item.action = { ftLink: ftLink, nftAddress: tokens[i*2 + 1] }
                item.totalSupply = totalSupply
                let { data } = await axios.get(`/api/${this.chainId}/markets/${markets[i]}`)
                data.floorPrice = ethers.BigNumber.from(data.floorPrice || 0)
                item.floorPrice = this.utils.bigToLabel(data.floorPrice, item.ftDecimals, 2)
                item.latestPrice = this.utils.bigToLabel(data.latestPrice, item.ftDecimals, 2)
                item.totalVolume = this.utils.bigToLabel(data.totalVolume, item.ftDecimals, 2)
                item.volume24h = this.utils.bigToLabel(data.volume24h, item.ftDecimals, 2)
                items.push(item)
            } catch(e) {
                /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
            }
        }
        this.allMarkets = items
    }, 
    methods: { }
};
</script>
<style lang="scss" scoped>
    .list-market{
        background-color: #fff;
        max-height: calc(100vh - 74px);
    }
</style>
