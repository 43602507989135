<script>
import { Line } from 'vue-chartjs'

export default {
    name: 'LineChart',
    extends: Line,
    props: [ 'chartData', 'chartOptions' ],
    async mounted () {
        this.renderChart(this.chartData, this.chartOptions)
    }
}
</script>
