
import Home from './components/Home.vue'
import Detail from './components/Detail.vue'
import Bid from './components/Bid.vue'
import Ask from './components/Ask.vue'
import UpdateAsk from './components/UpdateAsk.vue'
import UpdateBid from './components/UpdateBid.vue'
import Market from './components/Market.vue'
import Account from './components/Account.vue'
import FAQ from './components/FAQ.vue'
import Box from './components/Box.vue'
import ItemCatWL from './components/ItemCatWL.vue'
import MarketInfo from './components/MarketInfo.vue'
import ListMarket from './components/ListMarket.vue'

const routes = [
    { path: '/', component: Home },
    { path: '/faq', component: FAQ },
    { path: '/:chainId/faq', component: FAQ },
    { path: '/account', component: Account },
    { path: '/:chainId/account', component: Account, name: 'account' },
    { path: '/:chainId', component: Home, name: 'home' },
    { path: '/:chainId/market/:ft/:nft', component: Market, name: 'market' },
    { path: '/market/:ft/:nft', component: Market },
    { 
        path: '/market/:ft/:nft/:id',
        name: 'detaildefault',
        component: Detail
    },
    { 
        path: '/:chainId/market/:ft/:nft/:id',
        name: 'detail',
        component: Detail
    },
    { path: '/bid/:ft/:nft/:id', component: Bid },
    { path: '/:chainId/bid/:ft/:nft/:id', component: Bid, name: 'bid' },
    { path: '/bid/update/:ft/:nft/:id', component: UpdateBid },
    { path: '/:chainId/bid/update/:ft/:nft/:id', component: UpdateBid, name: 'updatebid' },
    { path: '/ask/:ft/:nft/:id', component: Ask },
    { path: '/:chainId/ask/:ft/:nft/:id', component: Ask, name: 'ask' },
    { path: '/ask/update/:ft/:nft/:id', component: UpdateAsk },
    { path: '/:chainId/ask/update/:ft/:nft/:id', component: UpdateAsk, name: 'updateask' },
    { path: '/:chainId/mintBox/:item', component: Box },
    { path: '/:chainId/mintItemCatWL/:item', component: ItemCatWL },
    { path: '/:chainId/info/:ft/:nft', component: MarketInfo, name: 'marketInfo' },
    { path: '/:chainId/list', component: ListMarket, name: 'listMarket' }
]

export default routes
