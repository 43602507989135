const { ethers } = require('ethers')
const MintItemCatWLAbi = require('@bazarion/protocol/abis/baza/MintItemCatWL.json')
const mintItemCatWLContracts = require('@bazarion/protocol/mintItemCatWL.json')
const { NETWORKS, MULTICALLS } = require('./constants')
const { Contract, Provider } = require('ethcall')
const BazaAbi = require('@bazarion/protocol/abis/baza/Baza.json')

class MintItemCatWL {
    constructor(addrMintItem, network, provider, signer) {
        this.mintItem = new ethers.Contract(addrMintItem, MintItemCatWLAbi, signer)
        this.address = this.mintItem.address
        this.network = network
        this.provider = provider
    }

    static async setMintItem(provider) {
        let signer = provider.getSigner()
        let ok =  true
        try {
            await signer.getAddress()
        } catch (e) {
            ok = false
        }
        if (provider.connection.url !== 'metamask' || !ok) {
            let randomWallet = ethers.Wallet.createRandom()
            signer = new ethers.Wallet(randomWallet.privateKey, provider)
        }
        return provider.getNetwork().then(net => {
            const network = NETWORKS.find(n => (n.chainId === '0x' + net.chainId.toString(16))) || []
            const contract = mintItemCatWLContracts.find(c => (c.ChainId === net.chainId))

            return new MintItemCatWL(contract.MintItemCatWL, network, provider, signer)
        })
    }

    async getCurrencyInfo(sale) {
        if (sale.currency === ethers.constants.AddressZero) {
            return {
                ftSymbol: this.network.nativeCurrency.symbol,
                ftDecimals: this.network.nativeCurrency.decimals
            }
        }

        const ethcallProvider = new Provider()
        return ethcallProvider.init(this.provider).then(async () => {
            return this.provider.getNetwork().then(async net => {
                const multi = MULTICALLS.find(n => (n.chainId === '0x' + net.chainId.toString(16)))
                ethcallProvider.multicall = { address: multi.address, block: 0 }

                let ftContract = new Contract(sale.currency, BazaAbi)

                let data = await ethcallProvider.all([
                    ftContract.symbol(),
                    ftContract.decimals()
                ])
                let ftSymbol = data[0]
                let ftDecimals = data[1]
                return { ftSymbol, ftDecimals }
            })
        })
    }

    buy(item, cat, round, wl, amount) {
        return this.mintItem.buy(item, cat, round, wl, amount)
    }
    
    buyETH(item, cat, round, wl, amount, price) {
        return this.mintItem.buyETH(item, cat, round, wl, amount, {
            value: ethers.BigNumber.from(price).mul(amount)
        })
    }

    getSales(item) {
        return this.mintItem.getSales(item)
    }

    getRounds(item, round) {
        return this.mintItem.getRounds(item, round)
    }

    getRoundsLength(item) {
        return this.mintItem.getRoundsLength(item)
    }

    getCategories(item, cat) {
        return this.mintItem.getCategories(item, cat)
    }

    getCategoriesLength(item) {
        return this.mintItem.getCategoriesLength(item)
    }

    getMaxItems(item, round, cat) {
        return this.mintItem.getMaxItems(item, round, cat)
    }

    getWhitelistedQty(item, round, cat, to, id) {
        return this.mintItem.getWhitelistedQty(item, round, cat, to, id)
    }

    async getWhitelistedQties(item, round, cat, to, ids) {
        const ethcallProvider = new Provider()
        return ethcallProvider.init(this.provider).then(async () => {
            return this.provider.getNetwork().then(async net => {
                const multi = MULTICALLS.find(n => (n.chainId === '0x' + net.chainId.toString(16)))
                ethcallProvider.multicall = { address: multi.address, block: 0 }

                let mintContract = new Contract(this.address, MintItemCatWLAbi)

                let calls = ids.map(id => {
                    return mintContract.getWhitelistedQty(item, round, cat, to, id)
                })

                let data = await ethcallProvider.all(calls)
                return data
            })
        })
    }
}

module.exports = MintItemCatWL
