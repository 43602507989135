<template>
    <div class="market-info">
        <b-container>
            <div class="is-flex header-account">
                <h1 class="is-flex address mb-0" v-if="nftSymbol">
                    <jazzicon
                        :address="marketAddress"
                        :diameter="30"
                        class="w-icon"
                    />
                    <b-link :href="`/${chainId}/market/${ftLink}/${nft}`"><span style="margin-left: 10px">{{ `${nftSymbol}/${ftSymbol}` }}</span></b-link>
                </h1>
                <b-skeleton v-else width="20%" height="40px"></b-skeleton>
            </div>
            <div class="mt-3">
                <b-row>
                    <b-col id="price-info" class="mb-3" sm="8">
                        <b-row cols="2" cols-sm="2" cols-md="4" cols-lg="4">
                            <b-col class="text-muted floor-price">
                                Floor Price
                            </b-col>
                            <b-col class="floor-price text-right">
                                <span class="h4">{{ utils.bigToLabel(basicStats.floorPrice, ftDecimals, 2) }}</span> <span :class="(priceMoves.floorPrice >= 0) ? 'text-success' : 'text-danger'"><b-icon :icon="(priceMoves.floorPrice >= 0) ? 'arrow-up' : 'arrow-down'"></b-icon> {{ (priceMoves.floorPrice || 0).toFixed(1) }} %</span>
                            </b-col>
                            <b-col class="text-muted">
                                Latest Price
                            </b-col>
                            <b-col class="text-right">
                                <span class="h4">{{ utils.bigToLabel(basicStats.latestPrice, ftDecimals, 2) }}</span> <span :class="(priceMoves.latestPrice >= 0) ? 'text-success' : 'text-danger'"><b-icon :icon="(priceMoves.latestPrice >= 0) ? 'arrow-up' : 'arrow-down'"></b-icon> {{ (priceMoves.latestPrice || 0).toFixed(1) }} %</span>
                            </b-col>
                        </b-row>
                        <b-row cols="2" cols-sm="2" cols-md="4" cols-lg="4">
                            <b-col class="text-muted">
                                Total Volume
                            </b-col>
                            <b-col class="text-right">
                                {{ utils.bigToLabel(basicStats.totalVolume, ftDecimals, 2) }}
                            </b-col>
                            <b-col class="text-muted">
                                Volume 24H
                            </b-col>
                            <b-col class="text-right">
                                {{ utils.bigToLabel(basicStats.volume24h, ftDecimals, 2) }}
                            </b-col>
                        </b-row>
                        <b-row cols="2" cols-sm="2" cols-md="4" cols-lg="4">
                            <b-col class="text-muted">
                                Total Supply
                            </b-col>
                            <b-col class="text-right">
                                {{ marketInfo.totalSupply }}
                            </b-col>
                            <b-col class="text-muted">
                                TVL
                            </b-col>
                            <b-col class="text-right">
                                {{ utils.bigToLabel(marketInfo.tvl || 0, ftDecimals, 2) }}
                            </b-col>
                        </b-row>
                        <b-row cols="2" cols-sm="2" cols-md="4" cols-lg="4">
                            <b-col class="text-muted">
                                Exchange Fee
                            </b-col>
                            <b-col class="text-right">
                                {{ utils.feeToCommon(marketInfo.exchangeFee || 0) }}%
                            </b-col>
                            <b-col class="text-muted">
                                Withdraw Fee
                            </b-col>
                            <b-col class="text-right">
                                {{ utils.feeToCommon(marketInfo.withdrawFee || 0) }}%
                            </b-col>
                        </b-row>
                        <b-row cols="2" cols-sm="2" cols-md="4" cols-lg="4">
                            <b-col class="text-muted">
                                Treasury
                            </b-col>
                            <b-col class="text-right">
                                {{ utils.feeToCommon(marketInfo.treasuryFee || 0) }}%
                            </b-col>
                            <b-col class="text-muted">
                                Stake Rate
                            </b-col>
                            <b-col class="text-right">
                                {{ utils.feeToCommon(marketInfo.stakeRate || 0) }}%
                            </b-col>
                        </b-row>
                        <b-row cols="2" cols-sm="2" cols-md="4" cols-lg="4">
                            <b-col class="text-muted">
                                Global Bid Price
                            </b-col>
                            <b-col class="text-right">
                                {{ utils.bigToLabel(marketInfo.bestBidMarketPrice || 0, ftDecimals, 2) }}
                            </b-col>
                            <b-col class="text-muted">
                                Share Supply
                            </b-col>
                            <b-col class="text-right">
                                {{ utils.bigToLabel(marketInfo.marketTotalSupply || 0, 18, 2) }}
                            </b-col>
                        </b-row>
                        <b-row cols="2" cols-sm="2" cols-md="4" cols-lg="4">
                            <b-col class="text-muted">
                                Market Cap
                            </b-col>
                            <b-col class="text-right">
                                {{ utils.bigToLabel(basicStats.floorPrice.mul(marketInfo.totalSupply || 0), ftDecimals, 2) }}
                            </b-col>
                            <b-col class="text-muted">
                                Owners
                            </b-col>
                            <b-col class="text-right">
                                {{ Object.keys(basicStats.owners).length }}
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col id="token-info" sm="4">
                        <b-card>
                            <b-row cols="2">
                                <b-col class="text-muted">
                                    Currency Symbol
                                </b-col>
                                <b-col class="text-right">
                                    {{ marketInfo.ftSymbol }}
                                </b-col>
                            </b-row>
                            <b-row cols="2">
                                <b-col class="text-muted">
                                    NFT Symbol
                                </b-col>
                                <b-col class="text-right">
                                    {{ marketInfo.nftSymbol }}
                                </b-col>
                            </b-row>
                            <b-row cols="2">
                                <b-col class="text-muted">
                                    NFT Name
                                </b-col>
                                <b-col class="text-right">
                                    {{ marketInfo.nftName }}
                                </b-col>
                            </b-row>
                            <b-row cols="2">
                                <b-col class="text-muted">
                                    Market Address
                                </b-col>
                                <b-col class="text-right">
                                    <b-link target="_blank" :href="utils.addressLink(chainId, marketAddress)">{{ utils.truncate(marketAddress, 11) }}</b-link>
                                </b-col>
                            </b-row>
                            <b-row cols="2">
                                <b-col class="text-muted">
                                    Currency Address
                                </b-col>
                                <b-col class="text-right">
                                    <b-link target="_blank" :href="utils.addressLink(chainId, ft)">{{ utils.truncate(ft, 11) }}</b-link>
                                </b-col>
                            </b-row>
                            <b-row cols="2">
                                <b-col class="text-muted">
                                    NFT Address
                                </b-col>
                                <b-col class="text-right">
                                    <b-link target="_blank" :href="utils.addressLink(chainId, nft)">{{ utils.truncate(nft, 11) }}</b-link>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
            <h3 class="mt-4">Charts</h3>
            <div>
                <b-tabs class="mt-4 mb-3" v-model="currentChart" @input="updateChart()">
                    <b-tab v-for="name in chartNames" :key="name" :title="name">
                        <LineChart v-if="chartLoaded" :chartData="chartData" :chartOptions="chartOptions" />
                    </b-tab>
                </b-tabs>
            </div>
            <h3 class="mt-4">Events</h3>
            <div class="log-table">
                <b-tabs class="mt-4" v-model="currentTab" @input="updateTab()">
                    <b-tab v-for="name in tabNames" :key="name" :title="name">
                        <b-table small :fields="fields" :items="allLogs" responsive="sm">
                            <template #cell(tx)="data">
                                <b-link :href="utils.txLink(chainId, data.value)" target="_blank">{{ utils.truncate(data.value, 16) }}</b-link>
                            </template>
                            <template #cell(from)="data">
                                <b-link :href="utils.addressLink(chainId, data.value)" target="_blank">{{ utils.truncate(data.value, 11) }}</b-link>
                            </template>

                            <template #cell(time)="data">
                                <i>{{ data.value }}</i>
                            </template>

                            <template #cell(name)="data">
                                {{ data.value }}
                            </template>

                            <template #cell(tokenId)="data">
                                <b-link :href="`/${chainId}/market/${ftLink}/${nft}/${data.value}`">{{ data.value }}</b-link>
                            </template>

                            <template #cell(price)="data">
                                {{ (data.value) ? utils.bigToLabel(data.value || 0, ftDecimals, 4) : ''}}
                            </template>

                            <template #cell(value)="data">
                                {{ (data.value) ? utils.bigToLabel(data.value || 0, ftDecimals, 4) : ''}}
                            </template>
                        </b-table>
                        <div class="mt-3 pb-4">
                            <b-pagination v-model="currentPage" :per-page="perPage" @input="loadPage(currentPage)" :total-rows="allLogsLength" align="center"></b-pagination>
                        </div>
                    </b-tab>
                    <template #tabs-end>
                        <b-nav-item role="presentation" @click.prevent="expandTab" v-if="tabNames.length < 4"><b>+</b></b-nav-item>
                        <b-nav-item role="presentation" @click.prevent="expandTab" v-else><b>-</b></b-nav-item>
                    </template>
                </b-tabs>
            </div>

        </b-container>
    </div>
</template>
<script>
import { Market, Multicall } from '@bazarion/sdk'
import { ethers } from 'ethers'
import axios from 'axios'
import moment from 'moment'
import LineChart from './LineChart.vue'

export default {
    name: 'MarketInfo',
    components: { LineChart },
    data: () => ({
        ftSymbol: '',
        nftSymbol: '',
        ftDecimals: 18,
        ftLink: '',
        ft: '',
        nft: '',
        marketAddress: '',
        isCopy: false,
        allLogs: [],
        allLogsLength: 0,
        currentPage: 1,
        allTxs: {},
        perPage: 10,
        currentTab: 0,
        tabNames: [ 'All', 'Sell', 'Buy' ],
        expandNames: [ 'All', 'Sell', 'Buy', 'Ask', 'Bid', 'RemoveAsk', 'UpdateAsk', 'RemoveBid', 'UpdateBid', 'BidMarket', 'Withdraw' ],
        currentTabName: 'All',
        marketInfo: {},
        chartLoaded: false,
        currentChart: 0,
        chartNames: [ 'Floor Prices', 'Historical Prices', 'Total Volume', 'Volume 24H', 'Owners' ],
        chartData: {
            labels: [],
            datasets: [
                {
                    label: 'Line Chart',
                    data: [],
                    fill: false,
                    borderColor: '#9D22C1',
                    backgroundColor: '#9D22C1',
                    borderWidth: 2,
                    pointRadius: 0,
                }
            ]
        },
        chartOptions: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    },
                    gridLines: {
                        display: true
                    }
                }],
                xAxes: [ {
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        maxTicksLimit: 10,
                        autoSkip: true,
                        maxRotation: 0,
                        minRotation: 0
                    }
                }]
            },
            legend: {
                display: true
            },
            responsive: true,
            maintainAspectRatio: false
        },
        statsData: {},
        priceMoves: {},
        basicStats: {
            floorPrice: ethers.BigNumber.from(0),
            latestPrice: ethers.BigNumber.from(0),
            totalVolume: ethers.BigNumber.from(0),
            volume24h: ethers.BigNumber.from(0),
            owners: 0
        },
        fields: [
            'tx',
            'time',
            'from',
            'name',
            'tokenId',
            'price',
            'value'
        ]
    }),
    created: async function () {
        let nft = this.$route.params.nft
        let ft = this.$route.params.ft
        this.ftLink = ft
        this.nftAddress = nft

        this.market = await Market.setMarket(ft, nft, this.provider)
        this.marketAddress = this.market.market
        this.ft = ethers.utils.getAddress(this.market.ft)
        this.nft = ethers.utils.getAddress(this.market.nft)

        await this.getMarketInfo()
        await this.getBasicStats()

        try {
            let stats = await axios.get(`/api/${this.chainId}/markets/${this.marketAddress}/stats/90d`)
            this.statsData = stats.data

            this.allTxs['All'] = this.statsData.transactions.results[0].series[0].values
            this.expandNames.forEach(ep => {
                if (ep !== 'All') {
                    this.allTxs[ep] = this.allTxs['All'].filter(t => (t[3] === ep))
                }
            })
            this.allLogsLength = this.allTxs[this.currentTabName].length
        } catch(e) { console.log(e) }

        let owners = await axios.get(`/api/${this.chainId}/markets/owners/${this.nft}/90d`)
        this.statsData.owners = owners.data
        
        let priceMoves= await axios.get(`/api/${this.chainId}/markets/${this.marketAddress}/24hchange`)
        this.priceMoves = priceMoves.data

        await this.updateChart()

        await this.loadLogPage(this.currentPage)

        this.chartLoaded = true
    }, 
    methods: {
        getBasicStats: async function () {
            try {
                let { data } = await axios.get(`/api/${this.chainId}/markets/${this.marketAddress}/nft/${this.nft}`)
                data.floorPrice = ethers.BigNumber.from(data.floorPrice || 0)
                this.basicStats = data
            } catch(e) {
                /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
            }
        },
        updateChart: async function () {
            this.chartLoaded = false
            this.chartData.labels = []
            this.chartData.datasets[0].data = []

            let data = this.statsData.floorPrices
            switch(this.currentChart) {
                case 1:
                    data = this.statsData.historicalPrices
                    break
                case 2:
                    data = this.statsData.volumeTotal
                    break
                case 3:
                    data = this.statsData.volume24h
                    break
                case 4:
                    data = this.statsData.owners
                    break
                default:
                    data = this.statsData.floorPrices
                    break
            }

            this.chartData.datasets[0].label = this.chartNames[this.currentChart]

            await this.loadChart(data)
            this.chartLoaded = true
        },
        loadChart: async function (data) {
            data.results[0].series[0].values.forEach(d => {
                let date = (new Date(d[0])).getDate()
                let month = (new Date(d[0])).getMonth() + 1
                this.chartData.labels.push(`${date}-${month}`)
                this.chartData.datasets[0].data.push(d[1])
            })
        },
        expandTab: function () {
            if (this.tabNames.length < 4) {
                this.tabNames = Object.assign([], this.expandNames)
            } else {
                this.tabNames = this.tabNames.slice(0, 3)
            }
        },
        updateTab: async function () {
            this.currentPage = 1
            this.allLogs = []
            await this.loadPage(this.currentPage)
        },
        loadPage: async function (currentPage) {
            this.currentTabName = this.tabNames[this.currentTab]
            this.allLogsLength = this.allTxs[this.currentTabName].length
            await this.loadLogPage(currentPage)
        },
        loadLogPage: async function (currentPage) {
            let rows = []
            let marketContract = this.market.marketContract
            let txs = this.allTxs[this.currentTabName]
            let page = currentPage
            let limit = this.perPage
            let max = ((page * limit) > this.allLogsLength) ? this.allLogsLength : page * limit

            let txsInPage = []
            for (let i = (page - 1) * limit; i < max; i++) {
                txsInPage.push(txs[i])
            }

            for (let t of txsInPage) {
                let timestamp = t[0]
                let tokenId = t[5]
                let txHash = t[6]
                let logs = (await this.provider.getTransactionReceipt(txHash)).logs
                let tx = await this.provider.getTransaction(txHash)
                for (let l of logs) {
                    try {
                        let log = marketContract.interface.parseLog(l)
                        if (log.name !== 'Transfer') {
                            let name = log.name
                            rows.push({
                                tx: txHash,
                                time: moment(timestamp).fromNow(),
                                timestamp: timestamp / 1000, 
                                from: tx.from,
                                name: name,
                                tokenId: tokenId || '',
                                price: (log.args.price || log.args.total || '').toString(),
                                value: (log.args.amount || log.args.price || log.args.total || '').toString()
                            })
                        }
                    } catch(e) {}

                }
            }
            this.allLogs = rows.sort((a, b) => {
                if (a.timestamp <= b.timestamp) return 1;
                if (a.timestamp > b.timestamp) return -1;
            })
        },
        async getMarketInfo() {
            let calls = await Multicall.setMulticall(this.ft, this.nft, this.provider)
            this.marketInfo = await calls.getMarketInfo()
            /*
            let {
                totalSupply, exchangeFee, withdrawFee, treasuryFee, stakeRate, tvl, ftDecimals,
                ftSymbol, nftSymbol, nftName, marketTotalSupply, bestBidMarketPrice
            } = await calls.getMarketInfo()
            */
            this.ftSymbol = this.marketInfo.ftSymbol
            this.nftSymbol = this.marketInfo.nftSymbol
            this.ftDecimals = this.marketInfo.ftDecimals
        },
        onCopy: function () {
            this.isCopy = true
            setTimeout(()=>{
                this.isCopy = false
            },2000)
        },
        onError: function () {
            this.isCopy = false
        }

    }
};
</script>
<style lang="scss">
    .market-info{
        background-color: #fff;
        max-height: calc(100vh - 74px);
        .header-account{
            align-items: center;
            .w-icon{
                height: 30px;
            }
        }
        .address{
            font-size: 30px;
            font-weight: 700;
        }
        .action{
            margin-left: 20px;
        }
        .copy-address{
            padding: 0px;
            display: inline-block;
        }
        .copy-address{
            background-color: transparent;
            color: #0065FF;
            margin-right: 16px;
            cursor: pointer;
        }
        .log-table th{
            color: #5E6C84;
            font-weight: 400;
            font-size: 14px;
        }
        #price-info{
            .row{
                .col{
                    border-bottom: solid 1px #ccc;
                    line-height: 40px;
                }
                .col.text-right{
    //                margin-right: 20px;
                }
            }
        }
        #token-info{
            .row{
                .col{
                    line-height: 35px;
                }
            }
        }
    }
</style>
